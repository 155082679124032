<template>
  <div class="app">
    <!-- <showtime type="4"> </showtime> -->

    <writetime :type="sj_type"></writetime>
    <div class="title">
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">
        <span @click="goback" style="cursor: pointer">{{
          $route.query.up
        }}</span>
        >
        {{ $route.query.tabtitle }}
        <span v-if="$route.query.title"> > {{ $route.query.title }}</span>
      </div>
    </div>
    <div class="pattern">
      <div>
        <span v-if="rows.type == 1">单选题</span>
        <span v-if="rows.type == 2">判断题</span>
        <span v-if="rows.type == 3">多选题</span>
      </div>

      <div class="right">
        <span>{{ index }}</span
        >/{{ total ? total : 0 }}
      </div>
    </div>
    <div class="topic">
      <div class="title1">
        {{ index }}.
        <span v-html="rows.subject"></span>
      </div>
      <transition name="fade">
        <div v-if="t_show">
          <ul>
            <li
              :class="item.active ? 'active' : ''"
              v-for="(item, inx) in rows.answers"
              :key="inx"
              @click="selectRow(item)"
            >
              <span v-html="item.answer"></span>
            </li>
          </ul>
        </div>
      </transition>

      <div class="msg" @click="look" v-if="$route.query.type != 1 && rows">
        <i class="icon iconfont icon-dengpao"></i><span>答案提示</span>
      </div>
      <!-- <div class="answer">您选择的答案是：<span></span></div> -->
      <transition name="fade"> </transition>
      <div v-show="a_show">
        <el-button
          @keyup.enter.native="submit;"
          class="btn"
          type="primary"
          :disabled="select.length == 0"
          @click="submit"
          >{{ index == rows.all ? "完成" : "下一题" }}</el-button
        >
      </div>
      <div v-show="!a_show">
        <el-button class="btn" type="primary" disabled>{{ num }}s</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import base from "../../libs/base";

export default {
  data() {
    return {
      title: "",
      textid: "", //下一题id
      rows: {},
      select: [], //选择的答案
      index: 1, //当前题数
      type: "",
      sj_type: 4,
      flag: true,
      t_show: true,
      a_show: true,
      num: 10,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  methods: {
    ...mapActions([
      "getMakeQuestion",
      "getCalculateSectionTest",
      "getErrorSubject",
      "getErrorBankResults",
      "getSectionTestScores",
      "geSubject_x",
      "geinsSubjectRecord_x",
      "getErrorSubject_x",
      "getjudgeCorrect_x",
      "screenCuttingDuration_x",
    ]),

    //查看提示
    look() {
      this.$alert(`正确答案${this.rows.correct}`, "答案提示", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
    },
    //1.模拟考试 5.章节练习 6.总题库
    getTest() {
      let that = this;
      this.geSubject_x({
        class_id: this.classid, //班级id
        student_id: this.userInfo.id, //学员id
        chapter_id: this.is_type == 1 ? this.$route.query.id : "", //章节id
        type: this.is_type, //1. 章节测试 2. 综合测试 3. 题库练习
        next: this.index == 1 ? "" : this.index, //下一题//第一题不用传
        record_id: this.index == 1 ? "" : this.textid, //做题记录id//第一题不用传
      }).then((res) => {
        if (res.successCode == 200) {
          if (this.$route.query.type != 6) {
            this.index =
              res.responseBody.subject.next == 0
                ? 1
                : res.responseBody.subject.next;
          }
          this.rows = res.responseBody.subject;
          if (this.index == 1) {
            this.textid = res.responseBody.record_id;
            this.total = res.responseBody.total;
          }
          if (this.$route.query.type == 6) {
            if (this.index == 1) {
              this.index =
                res.responseBody.subject.next == 0
                  ? 1
                  : res.responseBody.subject.next;
            }
          }
        } else if (res.errorCode == 1002 || res.successCode == 1002) {
          this.$message({
            message: "本套题目出错 请联系机构老师设置",
            type: "warning",
          });

          setTimeout(() => {
            that.$router.go(-1);
          }, 1500);
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //选择答案
    selectRow(item) {
      if (this.rows.type == 1 || this.rows.type == 2) {
        //单选
        this.rows.answers = this.rows.answers.map((res) => {
          res.active = res.answer == item.answer ? true : false;
          return res;
        });
        let arr = item.answer.substring(0, 1);
        this.select = [arr];
      } else if (this.rows.type == 3) {
        //多选
        item.active = !item.active;
        this.select = [];
        this.rows.answers.map((e) => {
          let str = e.answer.substring(0, 1);
          if (e.active) {
            this.select.push(str);
          }
        });

        let list = this.rows;
        this.rows = [];
        this.rows = list;
      }
    },
    goback() {
      this.$router.go(-1);
    },
    getcount() {
      let arr = setInterval(() => {
        this.num--;
        if (this.num == 0) {
          this.num = 10;
          clearInterval(arr);
        }
      }, 1000);
    },
    //提交答案
    submit() {
      let that = this;
      if (this.select.length == 0) {
        this.$message({
          message: "请选择答案",
          type: "warning",
        });
        return false;
      }
      if (this.$route.query.type == 1) {
        this.t_show = false;
        this.a_show = false;
        this.getcount();
        setTimeout(() => {
          that.t_show = true;
        }, 1000);
        setTimeout(() => {
          that.a_show = true;
        }, 10000);
      }
      if (this.$route.query.type == 2) {
        this.getErrorResuls();
      } else {
        this.getTestCall();
      }
    },
    getErrorResuls() {
      this.getjudgeCorrect_x({
        class_id: this.classid, //班级id
        student_id: this.userInfo.id, //学员id
        subject_id: this.errlist[this.index - 1].subject_id, //题目id
        student_choice: this.select.join(","), //学员选项
      }).then((res) => {
        this.$message({
          message: this.rows.correct == this.select.join(",") ? "正确" : "错误",
          type:
            this.rows.correct == this.select.join(",") ? "success" : "cancel",
        });

        if (res.successCode == 200) {
          if (this.index == this.total) {
            //答题完成
            setTimeout(() => {
              this.$message({
                message: "所有错题已经回答完毕",
                type: "success",
              });
              this.$router.back();
            }, 500);

            return false;
          }
          this.index++;
          this.rows = "";
          this.getErrorData();
        }
      });
    },
    //回调
    getTestCall() {
      this.geinsSubjectRecord_x({
        class_id: this.classid, //班级id
        student_id: this.userInfo.id, //学员id
        subject_id: this.rows.id, //题目id
        type: this.rows.type, //1. 单选题 2. 判断题 3. 多选题
        student_choice: this.select.join(","), //学员选项
        record_id: this.textid, //做题记录id
      }).then((res) => {
        this.$message({
          message: this.rows.correct == this.select.join(",") ? "正确" : "错误",
          type:
            this.rows.correct == this.select.join(",") ? "success" : "cancel",
        });

        if (res.successCode == 200) {
          this.select = [];
          if (this.index == this.total) {
            if (this.type == 5) {
              //总题库
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else if (this.type == 1) {
              //答题完成
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else {
              this.$message({
                message: "所有错题已经回答完毕",
                type: "success",
              });
              this.$router.back();
              return false;
            }
            return false;
          } else {
            this.index++;
            this.rows = "";
            this.getTest();
          }
        } else {
          if (this.index == this.total) {
            if (this.type == 5) {
              //总题库
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else if (this.type == 1) {
              //答题完成
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else {
              this.$message({
                message: "所有错题已经回答完毕",
                type: "success",
              });
              this.$router.back();
              return false;
            }
            return false;
          } else {
            this.index++;
            this.rows = "";
            this.getTest();
          }
        }
      });
    },
    //错题练习
    getErrorData() {
      let arr = {};
      if (this.index == 1) {
        arr = {
          class_id: this.classid, //班级id
          student_id: this.userInfo.id, //学员id
        };
      } else {
        arr = {
          class_id: this.classid, //班级id
          student_id: this.userInfo.id, //学员id
          subject_id: this.errlist[this.index - 1].subject_id,
        };
      }
      this.getErrorSubject_x(arr).then((res) => {
        if (res.successCode == 200) {
          if (this.index == 1) {
            this.errlist = res.responseBody.subject_ids;
            this.total = res.responseBody.total;
          }
          this.select = [];
          this.rows = res.responseBody.subject;
        } else {
          this.$message({
            message: res.message,
            type: (res.successCode == 200) == 200 ? "success" : "cancel",
          });
        }
      });
      ///////
      return;
      this.getErrorSubject({
        username: this.userInfo.username,
        class: this.classid,
        type: this.$route.query.type,
        page: this.index == 1 ? "1" : 1, //下一题 默认传1
      }).then((res) => {
        if (res.code == 200) {
          let ans = res.data.answer.split(",");
          ans = ans.map((e) => {
            console.log(e);
            let row = {
              title:
                e.indexOf("：") > -1
                  ? e.split("：")[1].replace(/\s+/g, "")
                  : e.split(":")[1].replace(/\s+/g, ""),
              flata:
                e.indexOf("：") > -1
                  ? e.split("：")[0].replace(/\s+/g, "")
                  : e.split(":")[0].replace(/\s+/g, ""),
            };
            return row;
          });
          let data = {
            id: res.data.id,
            ans: ans,
            iscorrect: res.data.iscorrect,
            kindof: res.data.kindof,
            title: res.data.title,
            all: res.data.allnum,
          };
          this.select = [];
          this.rows = data;
        } else {
          this.$router.go(-1);
          this.$message({
            message: res.msg,
            type: res.code == 200 ? "success" : "cancel",
          });
        }
      });
    },
    // 页面离开停止计时
    getdocument() {
      let that = this;
      if (document.hidden) {
        this.screen = false;
        // 页面被挂起
        that.leveTime = new Date();
      } else {
        this.screen = true;
        // 页面呼出
        that.backTime = new Date();
        var date3 = that.backTime.getTime() - that.leveTime.getTime();
        //停止计时
        that.screenCuttingDuration_x({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.classid,
          video_time: (date3 / 1000).toFixed(0),
          source: 4,
        });
      }
    },
    // WebSocket计时
    socketInit() {
      this.socket = new WebSocket(base.socketUrl);
      var that = this;

      this.Socket_sta = new Date();
      this.socket.onopen = function () {
        //补回连接中的时间段
        that.Socket_end = new Date();
        var date3 = that.Socket_end.getTime() - that.Socket_sta.getTime();
        that.screenCuttingDuration_x({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.classid,
          video_time: (date3 / 1000).toFixed(0),
          source: 4,
        });
        // }
        console.log("连接开始");
        that.socketLoad = true;
      };
    },
    // WebSocket计时
    sendSocketMsg() {
      this.time = setInterval(() => {
        this.lock = true;
        if (this.socketLoad && this.screen) {
          this.socket.send(
            JSON.stringify({
              type: 2,
              classId: this.classid,
              userId: this.userInfo.id,
              source: 4,
            })
          );
        }
      }, 5 * 60 * 1000);
    },
  },
  beforeDestroy() {
    let that = this;
    //必须内存地址相同才能移出方法
    this.flag1 = false;
    clearInterval(this.time);
    clearTimeout(this.human_time);
    clearTimeout(this.dynamic_time);
    clearTimeout(this.begin_time);
  },
  mounted() {
    let g = this;
    this.type = this.$route.query.type;
    let that = this;

    if (this.$route.query.type == 1) {
      this.title = "综合测试";
      this.is_type = 2;
      this.getTest();
      this.sj_type = 4;
      this.t_show = false;
      this.a_show = false;
      this.getcount();
      setTimeout(() => {
        that.t_show = true;
      }, 1000);
      setTimeout(() => {
        that.a_show = true;
      }, 10000);
    } else if (this.$route.query.type == 5) {
      this.title = "章节练习";
      this.is_type = 1;
      this.getTest();
      this.sj_type = 6;
    } else if (this.$route.query.type == 6) {
      this.title = "题库练习";
      this.is_type = 3;
      this.getTest();
      this.sj_type = 6;
    } else if (this.$route.query.type == 2) {
      this.title = "错题练习";
      this.is_type = 4;
      this.getErrorData();
      this.sj_type = 6;
    }
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13) {
        g.submit();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.app {
  height: 800px;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.pattern {
  margin-left: 11px;
  margin-top: 31px;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #137cfb;
  display: flex;
  justify-content: space-between;
  .right {
    color: #000;
    span {
      color: #fc5430;
    }
  }
}
::v-deep .topic {
  margin-left: 11px;

  margin-top: 17px;
  .title1 {
    margin-bottom: 43px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    img {
      width: 350px;
      height: auto;
    }
  }
  ul {
    .active {
      border: 2px solid #137cfb;
      padding: 11px 19px;
    }
    li {
      &:hover {
        border: 2px solid #137cfb;
        padding: 11px 19px;
      }
      img {
        width: 350px;
        height: auto;
      }
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      padding: 13px 21px;
      box-sizing: border-box;
      margin-bottom: 16px;

      background: #fafafa;
      width: 527px;
      // height: 50px;
    }
  }
  .msg {
    padding: 16px 0;
    text-align: left;
    font-size: 18px;
    color: #222222;

    .icon {
      color: #0039ff;
      font-size: 22px;
      margin-right: 5px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
  .answer {
    margin-top: 30px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    span {
      color: #137cfb;
    }
  }
  .btn {
    margin-top: 65px;
    margin-bottom: 500px;
    color: #fff;
    text-align: center;
    width: 235px;
    height: 39px;
    background: #137cfb;
  }
  .arduy {
    pointer-events: none;
    background: #ccc;
  }
}
</style>
